<template>
  <div id="app">
    <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                    :curr-page.sync="pagingParams.currPage"
                    :per-page.sync="pagingParams.perPage"
                    :per-page-list="perPageList"
                    :pages="pages"
                    :search-bar-place-holder="searchBarPlaceHolder"
                    :keyword.sync="pagingParams.keyword">
      <template #tools>
        <CButton color="info" size="sm" class="m-1" @click="openPrintChoiceModal" :disabled="!selected">QRコード取得</CButton>
      </template>

    </MyBeeSearchBar>
    <BeeGridTable
        ref="bee"
        border
        stripe

        :showFilter="false"
        :columns="columns"
        :loading="isLoading"
        :data="rows"
        :showPager="false"
        :maxHeight="gridCardHeight -30"
        highlightRow
        no-data-text="データがありません。"
        no-filtered-data-text=""
        :context="context"
        :span-method="customSpan"
        :showVerticalScrollBar="false"
        :showHorizontalScrollBar="false"
        @on-selection-change="selectionChanged">
      <!-- HEADER ソート可能にするために 必要-->
      <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
      <!-- cell -->
      <template slot-scope="{ row,index }" slot="created">
        {{row.created}}
      </template>

    </BeeGridTable>
    <CModal
        title="プリンター選択"
        :show.sync="showPrintChoiceModalFlg">
      <div>
        QRコードを出力して、印刷したいプリンターをご選択ください。
      </div>
      <template #footer>
        <CButton size="sm" color="info" @click="getQrCode('NORMAL')">一般のプリンター</CButton>
        <CButton size="sm" color="danger" @click="getQrCode('SATO')">SATO製ラベルプリンター</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>


// import SaveButtons from "@/component/parts/SaveButtons";
// import AgencyBranchManage from "@/component/parts/AgencyBranchManage";

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: "DeviceUploadHistory",
  mixins: [BeeMix],
  components: {
    MyBeeHeader,MyBeeSearchBar
  },
  props:{
    isShow:{type:Boolean,default:false},
    qrSizeForm:{type:Object}
  },
  data() {
    return {
      searchBarPlaceHolder:"日付(yyyy/MM/dd)で検索",
      pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "created", sort: "desc"}]}),
      columns: [
        {type: "selection", width: 60, maxWidth: 50,   align: "center", },
        // {title: 'GWシリアル番号', key: 'deviceId',headSlot: 'serialNumberHead'},
        { key: 'fileName',title: 'ファイル名'},
        { key: 'modelNumber',title: '機種'},
        { key: 'created',title: '登録日時',slot:"created",headSlot:"createdHead"},
      ],
      selected:false,
      showPrintChoiceModalFlg:false,
    };
  },methods:{
    openPrintChoiceModal(){
      this.showPrintChoiceModalFlg = true;
    },
    selectionChanged:function(){
      this.getCheckedList();
      this.selected = this.checkedList.length > 0;
    },
    getQrCode:function(printKind){
      this.showPrintChoiceModalFlg = false;
      let checkedDeviceUploadHistoryIdList = this.checkedList.map(function(item){
        return item.id;
      });
      this.isLoading = true;
      this.axios.post('/api/device/qrcode_history_download',
          {qrCodeList:checkedDeviceUploadHistoryIdList,qrSize:this.qrSizeForm,printKind:printKind}
          , {headers: {'Content-Type': 'application/json'},responseType: 'blob'}).then(
          response => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURI(response.headers.filename));
            document.body.appendChild(link);
            link.click();
          }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/device/searchDeviceUploadHistoryPaging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }
  },watch:{
    isShow:function(){
      if(this.isShow){
        this.goodPaging();
      }else{
        this.pagingParams.keyword = null;
        this.rows = [];
      }
    }
  }
};
</script>