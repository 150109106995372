<template>
  <div>

    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">QRコード発行</label>
        <div class="card-header-actions">
        </div>
      </CCardHeader>
      <CCardBody class="m-0 p-0 align-middle">

<!--        {{qrSizeForm}}-->
<!--        <div class="mb-5">-->
<!--          <CRow>-->
<!--            <CCol col="4">-->
<!--              全体幅:<input type="number" v-model="qrSizeForm.width" />-->
<!--            </CCol>-->
<!--            <CCol col="4">-->
<!--              全体高:<input type="number" v-model="qrSizeForm.height" />-->
<!--            </CCol>-->
<!--          </CRow>-->

<!--          <CRow>-->
<!--            <CCol col="4">-->
<!--              テキスト文字サイズ:<input type="number" v-model="qrSizeForm.fontSize" />-->
<!--            </CCol>-->

<!--            <CCol col="4">-->
<!--              テキスト文字フォント名:<input type="text" v-model="qrSizeForm.fontName" />-->
<!--            </CCol>-->

<!--          </CRow>-->

<!--          <CRow>-->
<!--            <CCol col="4">-->
<!--              1行目の開始位置X:<input type="number" v-model="qrSizeForm.line1X" />-->
<!--            </CCol>-->
<!--            <CCol col="4">-->
<!--              1行目の開始位置Y:<input type="number" v-model="qrSizeForm.line1Y" />-->
<!--            </CCol>-->
<!--          </CRow>-->

<!--          <CRow>-->
<!--            <CCol col="4">-->
<!--              2行目の開始位置X:<input type="number" v-model="qrSizeForm.line2X" />-->
<!--            </CCol>-->
<!--            <CCol col="4">-->
<!--              2行目の開始位置Y:<input type="number" v-model="qrSizeForm.line2Y" />-->
<!--            </CCol>-->
<!--          </CRow>-->

<!--          <CRow>-->
<!--            <CCol col="4">-->
<!--              3行目の開始位置X:<input type="number" v-model="qrSizeForm.line3X" />-->
<!--            </CCol>-->
<!--            <CCol col="4">-->
<!--              3行目の開始位置Y:<input type="number" v-model="qrSizeForm.line3Y" />-->
<!--            </CCol>-->
<!--          </CRow>-->


<!--          <CRow>-->
<!--            <CCol col="4">-->
<!--              QRの開始位置X:<input type="number" v-model="qrSizeForm.qrX" />-->
<!--            </CCol>-->
<!--            <CCol col="4">-->
<!--              QRの開始位置Y:<input type="number" v-model="qrSizeForm.qrY" />-->
<!--            </CCol>-->
<!--          </CRow>-->
<!--          <CRow>-->
<!--            <CCol col="4">-->
<!--              QR幅:<input type="number" v-model="qrSizeForm.qrWidth" />-->
<!--            </CCol>-->
<!--            <CCol col="4">-->
<!--              QR高:<input type="number" v-model="qrSizeForm.qrHeight" />-->
<!--            </CCol>-->
<!--          </CRow>-->
<!--        </div>-->
        <div class="form-group form-inline my-1">
          <label for="sel1"><h5>酸素濃縮装置</h5></label>
          <select id="sel1" v-model="modelNumbers[0]" autocomplete="device kind" class="form-control ml-1">
            <option value="">未選択</option>
            <option value="5A+">オキシウェル5A+</option>
            <option value="5B+">オキシウェル5B+</option>
          </select>

          <label for="sel2" class="ml-3"><h5>ゲートウェイ</h5></label>
          <select id="sel2" v-model="modelNumbers[1]" autocomplete="device kind" class="form-control ">
            <option value="">未選択</option>
            <option value="TCL-003">TCL-003</option>
          </select>

          <label for="sel2" class="ml-3"><h5>パルスオキシメータ</h5></label>
          <select id="sel3" v-model="modelNumbers[2]" autocomplete="device kind" class="form-control">
            <option value="">未選択</option>
            <option value="TM1121">TM1121</option>
          </select>

          <label for="sel2" class="ml-3"><h5>血圧計</h5></label>
          <select id="sel4" v-model="modelNumbers[3]" autocomplete="device kind" class="form-control">
            <option value="">未選択</option>
            <option value="UA-651BLE">UA-651BLE</option>
          </select>
        </div>
      </CCardBody>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template slot="tools">
            <!--            style="height:35px;"-->
<!--            <input type="text" size="20" placeholder="開始シリアル番号" v-model="serialFrom"></input>-->
<!--            <input type="text" size="20" placeholder="終了シリアル番号" v-model="serialTo"></input>-->
            <input type="text" v-model="serialFrom" placeholder="開始シリアル番号"
                   class="form-control border-0 m-1 mr-1"
                   style="background-color: #0f510f;color:white;">

            <input type="text" v-model="serialTo" placeholder="終了シリアル番号"
                   class="form-control border-0 m-1 mr-1"
                   style="background-color: #0f510f;color:white;">


<!--            <input type="text" size="20" placeholder="登録日時" readonly @click="searchRegistDate"></input>-->

<!--            <datepicker ref="datepicker" id="birthDay" :value="registDay" :language="ja"-->
<!--                        v-model="registDay" format="yyyy-MM-dd"-->
<!--                        typeable placeholder="登録日時。"/>-->
            <CButton v-if="isProvider()" size="sm" color="warning mr-1 my-1"  variant="outline" :pressed="allBranchFlg" @click="toggleAllBranch">全事業所</CButton>
            <CButton v-if="!isProvider()" color="info" size="sm" class="ml-2 my-1" @click="showFileUnit = true" >ファイル単位発行</CButton>

            <CButton color="info" size="sm" class="ml-2 my-1" @click="openPrintChoiceModal()" :disabled="!selected">発行</CButton>
          </template>
        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false"
            @on-selection-change="onSelectChange">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="modelNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="modelNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="serialNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="bdAddressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="patientNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="rent_fromHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="Head"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

          <template slot-scope="{ row,index }" slot="deviceId"><div class="text-left"><span>{{ row.deviceId}}</span></div></template>
          <template slot-scope="{ row,index }" slot="modelName"><div class="text-left"><span>{{ row.modelName}}</span></div></template>
          <template slot-scope="{ row,index }" slot="modelNumber"><div class="text-left"><span>{{ row.modelNumber}}</span></div></template>
          <template slot-scope="{ row,index }" slot="serialNumber"><div class="text-left"><span>{{ row.serialNumber}}</span></div></template>
          <template slot-scope="{ row,index }" slot="bdAddress"><div class="text-left"><span>{{ row.bdAddress}}</span></div></template>
          <template slot-scope="{ row,index }" slot="patientName"><div class="text-left"><span>{{ row.patientName}}</span></div></template>
          <template slot-scope="{ row,index }" slot="patientId"><div class="text-left"><span>{{ row.patientId}}</span></div></template>
          <template slot-scope="{ row,index }" slot="agencyName"><div class="text-left"><span>{{ row.agencyName}}</span></div></template>
          <template slot-scope="{ row,index }" slot="agencyBranchName"><div class="text-left"><span>{{ row.agencyBranchName}}</span></div></template>
          <template slot-scope="{ row,index }" slot="agencyId"><div class="text-left"><span>{{ row.agencyId}}</span></div></template>
          <template slot-scope="{ row,index }" slot="created"><div class="text-left"><span>{{ row.created}}</span></div></template>
          <template slot-scope="{ row,index }" slot="rent_from"><div class="text-left"><span>{{ row.rent_from}}</span></div></template>

        </BeeGridTable>

        <CModal :show.sync="showFileUnit"
                size="xl"
                :centered="true"
                title="ファイル単位QRコードダウンロード">

          <DeviceUploadHistory :is-show="showFileUnit" :qr-size-form="qrSizeForm"/>
          <!--                </CCardBody>-->
          <!--              </CCard>-->
          <template #footer-wrapper><span></span></template>
        </CModal>

      </CCardBody>
    </CCard>
    <CModal
        title="プリンター選択"
        :show.sync="showPrintChoiceModalFlg">
      <div>
        QRコードを出力して、印刷したいプリンターをご選択ください。
      </div>
      <template #footer>
        <CButton size="sm" color="info" @click="qrDownLoad('NORMAL')">一般のプリンター</CButton>
        <CButton size="sm" color="danger" @click="qrDownLoad('SATO')">SATO製ラベルプリンター</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
import {ja} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import datepicker from 'vuejs-datepicker';

import DeviceUploadHistory from "@/component/parts/DeviceUploadHistory";

export default {
  name: 'QrCode',
  components: {
    MyBeeHeader, MyBeeSearchBar,datepicker,DeviceUploadHistory
  },
  mixins: [BeeMix],
  data: () => {
    return {
      columns: [
        {type: "selection", width: 60,maxWidth: 50,align: "center"},
        {key: 'deviceId',slot: 'deviceId', title: '機器ID',width:50,align: "center"},
        {key: 'modelName',slot: 'modelName', title: '機種区分',headSlot:"modelNameHead",align: "center"},
        {key: 'modelNumber',slot: 'modelNumber', title: '機種',headSlot:"modelNumberHead",align: "center"},
        {key: 'serialNumber',slot: 'serialNumber', title: 'シリアル番号',headSlot:"serialNumberHead",align: "center"},
        {key: 'bdAddress',slot: 'bdAddress', title: 'BDアドレス',headSlot:"bdAddressHead",align: "center"},
        {key: 'patientName',slot: 'patientName', title: '患者名',headSlot:"patientNameHead",align: "center"},
        {key: 'patientId',slot: 'patientId', title: '登録No',headSlot:"patientIdHead",align: "center"},
        {key: 'agencyName',slot: 'agencyName', title: '販売店',headSlot:"agencyNameHead",align: "center"},
        {key: 'agencyBranchName',slot: 'agencyBranchName', title: '販売店事業所',headSlot:"agencyBranchNameHead",align: "center"},
        {key: 'agencyId',slot: 'agencyId', title: '販売店ID',headSlot:"agencyIdHead",align: "center"},
        {key: 'created',slot: 'created', title: '登録日時',headSlot:"createdHead",align: "center"},
        {key: 'rent_from',slot: 'rent_from', title: 'ペアリング日時',headSlot:"rent_fromHead",align: "center"},
      ],
        searchParams:{},
        searchTargets: [],
        // modelNumbers:["5A+","TCL-003","TM1121","UA-651BLE"],
        modelNumbers:["","","",""],
        isCollapsed:true,
        selected:false,
        serialFrom:null,
        serialTo:null,
        searchRegistDateModal:false,
        registDay:null,
        searchBarPlaceHolder:"シリアル番号、BDアドレス、患者名、機器ID、販売店、事業所名、登録日で検索",
        allBranchFlg:false,
        showFileUnit:false,
        qrSizeForm: {
          "width": 700,
          "height": "150",
          "line1X": "20",
          "line1Y": "40",
          "fontSize": "40",
          "fontName": "MS Gothic",
          "line2X": "20",
          "line2Y": "90",
          "line3X": "20",
          "line3Y": "140",
          "qrX": "530",
          "qrY": "25",
          "qrWidth": "125",
          "qrHeight": "125"
        },
        printKind:'NORMAL',
        showPrintChoiceModalFlg:false,
    }
  },
  methods:{
    searchRegistDate:function(){
      this.searchRegistDateModal=true;
    },
    onSelectChange:function(selectionsRow){
      this.selected = selectionsRow.length > 0;
    },
    openPrintChoiceModal(){
      this.showPrintChoiceModalFlg = true;
    },
    qrDownLoad:function(printKind){
      this.searchRegistDateModal=false;
      let selectedRowIds = this.$refs.bee.getSelection().map(function (row) {
          return row.deviceId
      });
      this.isLoading = true;
      this.axios.post('/api/device/qrCodeDownload',
          {qrCodeList:selectedRowIds,qrSize:this.qrSizeForm,printKind:printKind}
          , {headers: {'Content-Type': 'application/json'},responseType: 'blob'}).then(
          response => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURI(response.headers.filename));
            document.body.appendChild(link);
            link.click();
          }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },toggleAllBranch(){
      this.allBranchFlg = !this.allBranchFlg;
      this.goodPaging();
    },
    goodPaging(isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      let searchParams = {modelNumbers:this.modelNumbers,pagingForm:this.pagingParams,serialFrom:this.serialFrom,serialTo:this.serialTo,allBranchFlg:this.allBranchFlg};
      this.isLoading = true;
      this.axios.post('/api/device/searchDeviceListPaging',
          searchParams
          , {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
        this.searchParams = searchParams;
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    if(this.$store.state.user.role == "PROVIDER"){
      this.hiddenColumn("created");
    }
  },
  computed: {
    ja() {
      return ja;
    }
  },
  watch:{
      registDay: function () {
        if (this.registDay) {
          this.registDay = moment(this.registDay).format('YYYY-MM-DD');
        }
      },
    modelNumbers:function(){
      this.goodPaging();
    }
  }
}
</script>
